<template>
  <div>
    <AppButton @click="required=!required">
      required: {{ required }}
    </AppButton>
    <AppButton @click="readonly=!readonly">
      readonly: {{ readonly }}
    </AppButton>

    <div class="view">
      playground
    </div>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AppPlayground",
  data: () => ({
    required: false,
    readonly: false,
  }),
});
</script>

<style scoped>
.view {
  width: 95vw;
  margin: 20px auto;
  border: 3px solid rgb(238, 255, 0);
}
</style>
